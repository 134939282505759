import * as THREE from 'three'
import { colors } from '../utils';

const SEMRADIUS = 1.5;
const SEMDEPTH = 0.2;

export const newSemaphore = (scene: THREE.Scene, { offset }: { offset: THREE.Vector3 }) => {
    const curve = new THREE.LineCurve3(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, SEMDEPTH));
    const tube = new THREE.TubeGeometry(curve, 60, SEMRADIUS, 60); //, Math.PI * 2);
    const ring = new THREE.RingGeometry(SEMRADIUS, SEMRADIUS*.66, 60); //, Math.PI * 2);
    const ring2 = new THREE.RingGeometry(SEMRADIUS, 0, 60); //, Math.PI * 2);
    const mat = new THREE.MeshPhongMaterial({ color: colors[0], wireframe: false, shininess: .5, reflectivity: 0.3 });
    mat.side = THREE.DoubleSide;
    var tubeMesh = new THREE.Mesh(tube, mat);
    var ringMesh = new THREE.Mesh(ring, mat);
    var ring2Mesh = new THREE.Mesh(ring, mat);

    ringMesh.castShadow = true;

    for (const mesh of [tubeMesh, ringMesh, ring2Mesh]) {
        mesh.position.set(0, 3, -30);
        mesh.castShadow = true;

        mesh.position.add(offset);
        // mesh.rotateX(-.1)

        scene.add(mesh);
    }
    ringMesh.position.z += SEMDEPTH;
    // ring2Mesh.position.z -= 2;
    // ring2Mesh.rotateZ(Math.PI/2);

    return {
        ringMesh,
        tubeMesh,
        material: mat,
        set position(v: THREE.Vector3) {
            for (const mesh of [tubeMesh, ringMesh])
                mesh.position.set(v.x, v.y, v.z);
        },
        get position() {
            return ringMesh.position
        },
        dispose: () => {
            ring.dispose();
            ring2.dispose();
            tube.dispose();
            mat.dispose();
        }
    }
}

