import { newTube } from "../components/tube";
import { newSemaphore } from '../components/semaphore';
import { colors, randomInt } from '../utils';
import { MessagePool } from '../components/message';
import * as THREE from "three";
import { setupScene } from "./setup";
import { createText } from "../components/text";

// const { scene, controls, render, camera, transition } = setupScene();


type SemState = "ready" | "set" | "go";
export const semVals = ["ready", "set", "go"] as SemState[];

export const createLane = (scene: THREE.Scene, { offset } : { offset : THREE.Vector3 }) => {
    const assets = {
        tube: newTube(scene, { offset }),
        semaphore: newSemaphore(scene, { offset }),
        messagePool: MessagePool(scene, { offset }),
        text: createText(scene, { offset })
    }

    return {
        scene,
        // camera,
        // controls,
        assets,
        // render,
        semaphoreState: "ready" as SemState,
        // transition,
        config: {
            speed: [0, 0, -Math.random()/1.5 - 0.2],
            maxValue: randomInt(4, 10)
        },
        dispose: () => {
            assets.tube.dispose();
            assets.semaphore.dispose();
            assets.messagePool.dispose();
            assets.text.dispose();
        }
    };
}