import * as THREE from "three";
import { createLane, semVals } from "./scenes/lane";
import { colors, repeat } from "./utils";
import { MessageObj } from "./components/message";
import { setupScene } from "./scenes/setup";

const raycaster = new THREE.Raycaster();


const demo = (params?: { canvas?: HTMLCanvasElement, withControls?: boolean }) => {
    if(typeof window == "undefined")
        return;

    const { scene, transition, render, setAnimationLoop } = setupScene(params);
    const lanes = repeat((i) => createLane(scene, { offset: new THREE.Vector3(i * 5 - 50, 0, 0) }), 20)

    // const randomSemVal = () => semVals[randomInt(0, 3)];
    // const messagePool = MessagePool(scene);

    let _enabled = true;
    let _lastEnabled = new Date().getTime();


    function animate(laneScene: ReturnType<typeof createLane>) {
        if(!_enabled)
            return;
    
        // requestAnimationFrame(() => animate(laneScene))

        // setTimeout(() => animate(laneScene));

        // if(new Date().getTime() - _lastEnabled < 5000)
        //     return;

        const { assets } = laneScene;

        const semaphoreZ = laneScene.assets.semaphore.position.z;

        const messages = laneScene.assets.messagePool.messages;
        // console.log(messages.length)

        let messagesInFlight = messages.filter(m => m.mesh.position.z < semaphoreZ).length;

        const maxValue = laneScene.config.maxValue;
        const availableCapacity = maxValue - messagesInFlight;



        var intersects = raycaster.intersectObject(scene, true);

        const mouseover = (intersects.find(i => i.object.id == laneScene.assets.semaphore.tubeMesh.id));

        const laneBroken = mouseover || laneScene.semaphoreState !== "ready" ? Math.random() < .003 : Math.random() < .98;

        laneScene.semaphoreState = laneBroken ? "ready" : availableCapacity > 0 ? "go" : "set";
        const newSemColor = colors[semVals.findIndex(st => st == laneScene.semaphoreState)];
        assets.semaphore.material.color.setHex(newSemColor);

        const greenLight = laneScene.semaphoreState == "go";

        if ((Math.random() < .2 && laneScene.assets.messagePool.messages.length < laneScene.config.maxValue * 2)) {
            assets.messagePool.createMessageObj();
        }

        let prevMessageObj: MessageObj = {} as MessageObj;

        laneScene.assets.text.setText(`${messagesInFlight}/${maxValue}`)
        laneScene.assets.text.setColor(newSemColor)

        const coeff: number = .3; //_lastEnabled < 30000 ? 3 : 1;
        for (const obj of assets.messagePool.messages) {                
            const cs = laneScene.config.speed;
            const speed = [cs[0], cs[1], coeff * cs[2]];  //obj.config.speed; // laneScene.config.speed; 

            const distanceToSemaphore = (obj.mesh.position.z - semaphoreZ);
            const incoming = distanceToSemaphore > 0;
            const tooCloseTosemaphore = distanceToSemaphore < 3;
            const tooClose =
                prevMessageObj.mesh &&
                (obj.mesh.position.z + speed[2] - (prevMessageObj.mesh.position.z + prevMessageObj.config.dimensions[2]) < .2
                    // Math.abs(laneScene.speed[2])
                );

            if (incoming && (!greenLight && (tooCloseTosemaphore || tooClose))) {

            } else
            if(speed[2] != 0) {
                // obj.mesh.position.add(new THREE.Vector3(...speed));
                obj.mesh.position.lerp(new THREE.Vector3(...speed).add(obj.mesh.position), 0.5);
                if (Math.abs(obj.mesh.position.z) > 200)
                    assets.messagePool.removeMessageObj(obj);
            }
            prevMessageObj = obj;
        }

    }

    setAnimationLoop(() => {
        lanes.forEach(l => animate(l))
        transition();

        render();
    });
    const disposeLanes = () => lanes.forEach(l => {
        l.dispose();
        scene.clear();
    });

    const dispose = () => {
        disposeLanes();
        setAnimationLoop(null);
        _enabled = false;
    }

    return {
        transition,
        dispose
    }
    // animate(lanes[0])
}

export const LaneDemo = demo;
// demo({ withControls: true })

