import * as THREE from "three";

export const maxDim = 3;
export const maxSpeed = 3;

// export const colors = [0x8B0000, 0xFF8C00, 0x006400];
export const colors = [0x7e210a, 0xb8670a, 0x39530a]

export const randomInt = (min: number, max: number = 0) => Math.floor(Math.random() * (max - min) + min);
export const repeat = <T>(f: (i: number) => T, n: number) => " ".repeat(n).split("").map((_, i) => f(i));
export const range = (n: number) => " ".repeat(n).split("");

export const randomColor = () => colors[randomInt(0, colors.length)];



export const interpolateScalar = (from: number | string = 0, to: number | string = 0, alpha: number) => {
    return (Number(from) || 0) + ((Number(to) || 0) - (Number(from) || 0)) * alpha;
}

export const interpolate = (v1: THREE.Vector3, v2: THREE.Vector3, alpha: number) => {
    return v1.add(v2.clone().sub(v1.clone()).multiplyScalar(alpha))
}

export const interpolateEuler = (v1: THREE.Euler, v2: THREE.Euler, alpha: number) => {
    const a1 = v1.toArray();
    const a2 = v2.toArray();
    return new THREE.Euler(
        interpolateScalar(a1[0], a2[0], alpha),
        interpolateScalar(a1[1], a2[1], alpha),
        interpolateScalar(a1[2], a2[2], alpha),
    )
}