import * as THREE from 'three';

// class CustomSinCurve extends THREE.Curve {

// 	constructor( scale = 1 ) {
// 		super();
// 		this.scale = scale;
// 	}

// 	getPoint( t, optionalTarget = new THREE.Vector3() ) {

// 		const tx = t * 3 - 1.5;
// 		const ty = Math.sin( 2 * Math.PI * t );
// 		const tz = 0;

// 		return optionalTarget.set( tx, ty, tz ).multiplyScalar( this.scale );
// 	}
// }

// const path = new CustomSinCurve( 10 );
const curve = new THREE.LineCurve3(new THREE.Vector3(0, 0, 10), new THREE.Vector3(0, -7, -100))

export const newTube = (scene: THREE.Scene, { offset } : { offset : THREE.Vector3 }) => {
    const geometry = new THREE.TubeGeometry( (curve), 5, 4, 30, true );
    const material = new THREE.MeshPhysicalMaterial( { color: 0x00ff00, opacity: 1 } );
    // material.opacity = .3;

    const mesh = new THREE.Mesh(geometry, material);
    mesh.rotateY(Math.PI);
    mesh.position.z = -100;

    mesh.position.add(offset);

    // scene.add(mesh);
  
    return {
        mesh,
        dispose: () => {
            geometry.dispose();
            material.dispose();
        } 
    }
}
