import * as THREE from "three";
import { maxDim, randomColor, randomInt, repeat } from "../utils";

const MAXZ = 50;
const MINDEPTH = 0.5;
const MAXDEPTH = 2;

type Message = {
    dimensions: [number, number, number],
    position: [number, number, number],
    color: number,
    speed: [number, number, number],
    wasMoving: boolean
};

const newMessage = () => {
    const d = MINDEPTH + (MAXDEPTH - MINDEPTH)*Math.random();
    const col = new THREE.Color(randomColor());
    const currHSL = { h: 0, s: 0, l: 0 };
    col.getHSL(currHSL);
    col.offsetHSL(0, -(currHSL.s * .75),  0);
    return {
        dimensions: [3, 0.2, d],
        position: [0,0,MAXZ - d], //[-10 * randomInt(-5, 5), 0, randomInt(0,10) * 10],
        color: col.getHex(),
        speed: [0, 0, -Math.random() - .5]//repeat(() => Math.random(), 3),
    } as Message;
}

const newMessageObj = ({ offset } : { offset : THREE.Vector3 }) => {
    const m = newMessage();


    const geometry = new THREE.BoxGeometry(...m.dimensions);
    const material = 
    new THREE.MeshPhongMaterial( { 
    // new THREE.MeshStandardMaterial({
        color: m.color,
        
        // reflectivity: 1,
        // flatShading: false,
        // opacity: 0.5,
        // antialias: true
        //  transparent: true
        // wireframe: true,
    })
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.add(new THREE.Vector3(...m.position))
    mesh.position.add(offset);

    return {
        config: m,
        geometry,
        material,
        mesh
    }
};

export type MessageObj = ReturnType<typeof newMessageObj>;

export const MessagePool = (scene: THREE.Scene, { offset } : { offset : THREE.Vector3 }) => {
    const messages: MessageObj[] = [];
    const stats : { 
        lastCreated: number
    } = { lastCreated: Date.now() }

    const createMessageObj = () => {
        if(Date.now() - stats.lastCreated < 500)
            return;
        

        const lastMessage = messages[messages.length - 1];;

        if(lastMessage && lastMessage.mesh.position.z > MAXZ - MAXDEPTH - 1)
            return;

        const nm = newMessageObj({ offset });
        messages.push(nm);
        scene.add(nm.mesh);
        stats.lastCreated = Date.now()
    }
    const removeMessageObj = (obj: MessageObj) => {
        messages.splice(messages.findIndex(o => o == obj), 1);
        scene.remove(obj.mesh);
        obj.geometry.dispose();
        obj.material.dispose();
        // scene.remove(obj.geometry);
    }

    return {
        messages,
        createMessageObj,
        removeMessageObj,
        stats,
        dispose: () =>{
            messages.forEach(m => (m.material.dispose(), m.geometry.dispose()))
        }
    }
}

