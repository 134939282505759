import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { interpolate, interpolateEuler, interpolateScalar, randomInt } from '../utils';


export const CAMERA_POSITIONS = {
    default: {"position":[0.6831572183475944,16.527276699397166,46.76915097920783],"rotation":[0.18078492661692352,0.001453702483870089,-0.0002657084584092357],"lookAt":[0.6028425217559712,26.46102452866185,-7.578793207483687],"quaternion":[0.09026929635787236,0.0007358763866814188,-0.00006669936921083138,0.9959171191279885],"zoom":1},
    front: {"position":[-2.8685800551618423,13.553381071824308,-3.90069712726373],"rotation":[-0.5251192024779966,-0.004768739655907918,-0.0027628914830030104],"lookAt":[-2.854423189459691,12.065144740588876,-6.469370814468734],"quaternion":[-0.2595491135772248,-0.0026612078486951247,-0.000715227874264005,0.9657259777303034],"zoom":1},
    top: {"position":[-3.2019900787058595,22.360682710111853,-11.720021836087396],"rotation":[-1.5707963285422444,0.0000010000429227498554,1.5725435975312971],"lookAt":[-3.2020124403454164,0.0000029351251346087164,-11.720021797015518],"quaternion":[-0.4995627417691284,0.5004368770482297,0.5004363765893588,0.49956324135432517],"zoom":1},
    back: {"position":[-0.16167240290704446,13.751507657420285,-46.264081503606235],"rotation":[-2.85267044339042,-0.0033496989917670087,-3.1405969932746083],"lookAt":[0,0,0],"quaternion":[0.0002515340632341669,0.9895822378768093,0.14395812421346724,-0.0017290697732933367],"zoom":1},
    frontLeft: {"position":[24.333426166143198,7.764864824455594,-4.0166389046872375],"rotation":[0.2341018393173953,0.5398650147806064,-0.1219721006867906],"lookAt":[13.96023440122767,11.780348695663053,-20.854866450612576],"quaternion":[0.09620406160755039,0.271209353752618,-0.027254881069596993,0.9573126116505412],"zoom":1}
};

export type CameraPosition = (typeof CAMERA_POSITIONS)[keyof typeof CAMERA_POSITIONS]

const isNan = (val: any) => val != val;

import GUI from 'lil-gui';

let gui: GUI;

export const setupScene = (params?: { canvas?: HTMLCanvasElement, withControls?: boolean }) => {
    const scene = new THREE.Scene();

    const root = window; //(params?.canvas ? params.canvas.parentElement : window) || ({} as any);
    const w = root.innerWidth;
    const h = root.innerHeight;

    const camera = new THREE.PerspectiveCamera(90, w / h, 10, 2000)
    camera.position.z = 20;
    camera.position.y = 10;
    // camera.position.x = 40;
    // camera.po
    camera.rotation.set(0.1, 0.4, 0)
    camera.updateProjectionMatrix();

    camera.lookAt(0, 10, -30);

    const renderer = new THREE.WebGLRenderer({
        canvas: params?.canvas,
        antialias: true,
        alpha: true
        // powerPreference: "low-power" // "high-performance", "low-power"
    });
    renderer.setSize(w,h) //(window.innerWidth, window.innerHeight)
    document.body.appendChild(renderer.domElement)

    // camera

    const controls = new OrbitControls(camera, renderer.domElement)


    window.addEventListener('resize', onWindowResize, false)

    function render() {
         renderer.render(scene, camera)
    }
    renderer.setClearColor(0x000000, 0)

    // function setAnimationLoop(animate: () => void) {
    // }

    function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight
        camera.updateProjectionMatrix()
        renderer.setSize(window.innerWidth, window.innerHeight)
        // render()
    }


    const alight = new THREE.AmbientLight(0x704214, 4); // soft white light 0x0f0f0f // sepia 0x704214
    alight.position.set(0,0,50)
    const light = new THREE.SpotLight(0x262626, 12, -20); //new THREE.SpotLight(0x262626, 14); // soft white light
    light.angle = 40;
    light.position.set(0, 100, -64.8);
    light.target.position.set(0, 0, -100);


    // light.target.
    scene.add(light);
    scene.add(alight);

    // scene.fog = new THREE.Fog(0x704214, .5);
    // scene.background = new THREE.Color(0x704214);
    // renderer.setClearColor( 0x000000, 0 );

    const transitionState = {
        from: {
            position: camera.position,
            rotation: camera.rotation,
            quaternion: camera.quaternion,
            lookAt: controls.target,
            zoom: 1
        },
        to: {
            position: camera.position,
            rotation: camera.rotation,
            quaternion: camera.quaternion,
            lookAt: controls.target,
            zoom: 1
        },
        alpha: 2
    }

    const interpolateQuaternion = (q1: THREE.Quaternion, q2: THREE.Quaternion, alpha: number) => {
        const a1 = q1.toArray();
        const a2 = q2.toArray();
        return new THREE.Quaternion().fromArray(a1.map((v, i) => interpolateScalar(v, a2[i], alpha)))
        // return q1.clone().slerp(q2.clone(), alpha)
    }

    const delta = 0.5; //0.001;
    let prevPos = "";
    const transition = (camPos?: keyof typeof CAMERA_POSITIONS, alpha?: number) => {
        if(((camPos == prevPos) || !camPos) && transitionState.alpha >= 1)
            return;

        if (alpha)
            transitionState.alpha = alpha;

        if (transitionState.alpha < 1)
            transitionState.alpha += delta;

        let pos = {
            position: interpolate(transitionState.from.position, transitionState.to.position, transitionState.alpha),
            rotation: interpolateEuler(transitionState.from.rotation, transitionState.to.rotation, transitionState.alpha),
            quaternion: interpolateQuaternion(transitionState.from.quaternion, transitionState.to.quaternion, transitionState.alpha),
            lookAt: interpolate(transitionState.from.lookAt, transitionState.to.lookAt, transitionState.alpha),
            zoom: interpolateScalar(transitionState.from.zoom, transitionState.to.zoom, transitionState.alpha)
        }

        if (camPos) { //Math.random() < 0.0001) { // && (transitionState.alpha >= 1)) {
            // const keys = Object.keys(CAMERA_POSITIONS);
            const newpos = camPos; //keys.filter(v => v != prevPos)[randomInt(0, keys.length - 1)];

            // console.log("New pos: ", newpos)

            const _pos = (CAMERA_POSITIONS as any)[newpos] as CameraPosition;
            transitionState.from = {
                position: camera.position.clone(),
                rotation: camera.rotation.clone(),
                quaternion: camera.quaternion.clone(),
                lookAt: controls.target.clone(),
                zoom: (controls as any).zoom0
            };
            transitionState.to = {
                position: new THREE.Vector3(..._pos.position),
                rotation: new THREE.Euler(..._pos.rotation),
                quaternion: new THREE.Quaternion(..._pos.quaternion),
                lookAt: new THREE.Vector3(..._pos.lookAt),
                zoom: (_pos as any).zoom || 1
            };
            transitionState.alpha = 0;
            pos = transitionState.from;

            if (isNaN(transitionState.from.position.x))
                debugger;

            prevPos = newpos;
        }


        const worldPos = pos.position; //.applyMatrix4(camera.matrixWorld);
        camera.position.set(worldPos.x, worldPos.y, worldPos.z);


        camera.setRotationFromQuaternion(pos.quaternion.normalize());
        camera.lookAt(pos.lookAt);
        controls.target = pos.lookAt;
        controls.update()
    }

    transition('default');

    if (params?.withControls && typeof document != "undefined") {
        // controls.enabled = true;
        gui = new GUI();

        const c = gui.add({}, "transition", Object.keys(CAMERA_POSITIONS).reduce((r, c) => ({ ...r, [c]: c }), {}));
        c.onChange(transition);

        const positions = { text: JSON.stringify(CAMERA_POSITIONS, null, "\t") }

        const txt = gui.add(positions, "text")

        const log = () => {
            const t = {
                position: camera.position.toArray().filter(o => typeof o == "number"),
                rotation: camera.rotation.toArray().filter(o => typeof o == "number"),
                lookAt: controls.target.toArray(),
                quaternion: camera.quaternion.toArray().filter(o => typeof o == "number"),
                zoom: (controls as any).zoom0
            };
            txt.setValue(JSON.stringify(t));
        };
        gui.add({ log }, "log");

        gui.add({ "lightY": light.position.y }, "lightY", -100, 100).onChange((val: number) => light.position.y = val)
        gui.add({ "lightZ": light.position.z }, "lightZ", -100, 100).onChange((val: number) => light.position.z = val)
        gui.add({ "lightAngle": light.angle }, "lightAngle", 0, Math.PI).onChange((val: number) => light.angle = val)
        gui.add({ "lightIntensity":  light.intensity }, "lightIntensity", 0, 100).onChange((val: number) => light.intensity = val)
        gui.addColor({ spotColor: light.color }, "spotColor").onChange((val: number) => {
            light.color = new THREE.Color(val)
        });
       
    }

    return {
        scene,
        camera,
        controls,
        render,
        transition,
        setAnimationLoop: renderer.setAnimationLoop.bind(renderer)
    }
};